import { ApolloClient, NormalizedCacheObject } from "@apollo/client";

import {
  GetShippableCountriesAndCurrencies,
  GetShippableCountriesAndCurrenciesQuery,
  GetShippableCountriesAndCurrenciesQueryVariables,
  GetShopEligibleForSubscriptionMigration,
  GetShopEligibleForSubscriptionMigrationQuery,
  GetShopEligibleForSubscriptionMigrationQueryVariables,
  GetShopEligibleForSubscriptions,
  GetShopEligibleForSubscriptionsQuery,
  GetShopEligibleForSubscriptionsQueryVariables,
  GetShopLocales,
  GetShopLocalesQuery,
  GetShopLocalesQueryVariables,
  GetShopSubscriptionGatewayEnabled,
  GetShopSubscriptionGatewayEnabledQuery,
  GetShopSubscriptionGatewayEnabledQueryVariables,
} from "./api";

import { apolloShopifyQuery } from ".";

export function queryShopEligibleForSubscriptions(client: ApolloClient<object>) {
  return apolloShopifyQuery<GetShopEligibleForSubscriptionsQuery, GetShopEligibleForSubscriptionsQueryVariables>(
    {
      query: GetShopEligibleForSubscriptions,
    },
    client
  );
}

export function queryShopSubscriptionGatewayEnabled(client: ApolloClient<object>) {
  return apolloShopifyQuery<
    GetShopSubscriptionGatewayEnabledQuery,
    GetShopSubscriptionGatewayEnabledQueryVariables
  >(
    {
      query: GetShopSubscriptionGatewayEnabled,
    },
    client
  );
}

export function queryShopEligibleForSubscriptionMigration(client: ApolloClient<object>) {
  return apolloShopifyQuery<
    GetShopEligibleForSubscriptionMigrationQuery,
    GetShopEligibleForSubscriptionMigrationQueryVariables
  >(
    {
      query: GetShopEligibleForSubscriptionMigration,
    },
    client
  );
}

export function queryShopLocales(client: ApolloClient<object>) {
  return apolloShopifyQuery<GetShopLocalesQuery, GetShopLocalesQueryVariables>(
    {
      query: GetShopLocales,
    },
    client
  );
}

export async function queryShopShippableCountriesAndCurrencies(client: ApolloClient<NormalizedCacheObject>) {
  const query = await apolloShopifyQuery<
    GetShippableCountriesAndCurrenciesQuery,
    GetShippableCountriesAndCurrenciesQueryVariables
  >(
    {
      query: GetShippableCountriesAndCurrencies,
    },
    client
  );
  if (query.type === "error") {
    throw new Error(`Request failed to query shippable countries. ${query.message}`);
  }

  return query;
}
